import React, { ReactElement, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import StoreButton from "../StoreButton";
import NewsLetterForm from "./NewsLetterForm";
import BottomSection from "./BottomSection";
import { FaqChat } from "./FaqChat";

import { ReactComponent as PhoneIcon } from "../../assets/phone-white-24.svg";
import { ReactComponent as MailIcon } from "../../assets/mail-white-24.svg";

import styles from "./styles.mod.scss";
import { useScroll } from "../../hooks/useScroll";

interface FooterProps {
  isFaqPage?: boolean;
  isScroll?: boolean;
}
declare global {
  interface Window {
    fcWidget: any;
  }
}

const Footer = ({ isFaqPage, isScroll }: FooterProps): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "bg.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2101) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const getFooterClass = () => {
    if (isFaqPage) {
      return `${styles.footer} ${styles.footer_faq} `;
    }
    return styles.footer;
  };
  const contactRef = useRef(null);
  useScroll(isScroll || false, contactRef);

  let mounted = true;
  useEffect(() => {
    if (mounted) {
      window.addEventListener("scroll", () => {
        const stickyButton = document.getElementById("chat-icon-sticky");
        const stickyButtonSmall = document.getElementById(
          "chat-icon-sticky-small"
        );

        if (stickyButton) {
          stickyButton.style.visibility = "visible";
        }

        if (stickyButtonSmall) {
          stickyButtonSmall.style.visibility = "visible";
        }

        const mainIllustrationElement = document.getElementById("main-illu");
        const mainIlluTabElement = document.getElementById("main-illu-tablet");
        if (
          mainIllustrationElement &&
          mainIlluTabElement &&
          stickyButton &&
          stickyButtonSmall
        ) {
          if (
            mainIllustrationElement.getBoundingClientRect().bottom + 50 <=
              window.innerHeight &&
            mainIlluTabElement.getBoundingClientRect().bottom + 50 <=
              window.innerHeight
          ) {
            stickyButton.style.visibility = "visible";
            stickyButtonSmall.style.visibility = "visible";
          } else {
            stickyButton.style.visibility = "hidden";
            stickyButtonSmall.style.visibility = "hidden";
          }
        }

        const footerElement = document.getElementById("footer");
        if (
          footerElement &&
          footerElement.getBoundingClientRect().top <=
            window.innerHeight - 250 &&
          stickyButton &&
          stickyButtonSmall
        ) {
          stickyButton.style.visibility = "hidden";
          stickyButtonSmall.style.visibility = "hidden";
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className={getFooterClass()} id="footer">
      <div className={`${styles.footer__container} container`}>
        <NewsLetterForm />
      </div>
      <div className={styles.footer__background}>
        <div className={`${styles.footer__contacts} container`}>
          <div className={`${styles.footer__faqChat} d-none d-sm-flex`}>
            <FaqChat />
          </div>
          <div className={styles.footer__contacts_flexBlock}>
            <div className={styles.footer__contacts_mailPhone}>
              <div>
                <span
                  ref={contactRef}
                  id="contact"
                  className={styles.footer__contactsTitle}
                >
                  Schreib uns:
                </span>
                <div className={styles.footer__contactsContent}>
                  <MailIcon
                    preserveAspectRatio="xMinYMin meet"
                    viewBox="0 0 24 24"
                  />
                  <a
                    href="mailto:hello@stromee.de"
                    className={styles.footer__contactsContent_text}
                  >
                    hello@stromee.de
                  </a>
                </div>
              </div>
              <div>
                <span className={styles.footer__contactsTitle}>
                  Ruf uns an:
                </span>
                <div className={styles.footer__contactsContent}>
                  <PhoneIcon
                    preserveAspectRatio="xMinYMin meet"
                    viewBox="0 0 24 24"
                  />
                  <a
                    href="tel:030 - 20006042"
                    className={styles.footer__contactsContent_text}
                  >
                    030 - 20006042
                  </a>
                </div>
              </div>
            </div>

            <div
              className={`${styles.footer__contacts_stores} d-none d-sm-block`}
              id="store-buttons"
            >
              <StoreButton
                url="https://apps.apple.com/de/app/id1521872083"
                target="_blank"
                text="App Store"
                className={styles.footer__storeButton}
              />
              <StoreButton
                url="https://play.google.com/store/apps/details?id=com.stromee.android"
                target="_blank"
                text="Google Play"
                className={styles.footer__storeButton}
              />
            </div>
          </div>
        </div>

        <Img
          className={styles.footer__background_image}
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ minHeight: "100%", height: "100%" }}
        />
      </div>
      <BottomSection />
    </div>
  );
};

export default Footer;
