import React, { ReactElement } from "react";
import classnames from "classnames";

import StoreButton from "../StoreButton";
import { CustomizedDialogs } from "./Modal";
import { FaqChat } from "./FaqChat";
import { ReactComponent as InstagramIcon } from "../../assets/combined-shape.svg";
import { ReactComponent as FacebookIcon } from "../../assets/combined-shape-copy-4.svg";
import { ReactComponent as PinIcon } from "../../assets/combined-shape-copy-5.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/combined-shape-copy-6.svg";
import { ButtonChatSticky } from "./styled";
import { ReactComponent as ChatIconSticky } from "../../assets/chat-sticky.svg";

import styles from "./styles.mod.scss";

const BottomSection = (): ReactElement => {
  return (
    <div className={styles.footer__bottomSection}>
      <div
        className={classnames("container", styles.footer__bottomSectionWrapper)}
      >
        <div className={styles.footer__bottomLeft}>
          <p className={styles.footer__stromee} id="bottom">
            {`© stromee ${new Date().getFullYear()}`}
          </p>
          <div className={styles.footer__socialButtons}>
            <a
              className={styles.footer__link}
              href="https://www.facebook.com/stromee.de/"
              rel="noreferrer"
              target="_blank"
            >
              <FacebookIcon
                preserveAspectRatio="xMinYMin meet"
                viewBox="0 0 40 40"
              />
            </a>
            <a
              className={styles.footer__link}
              href="https://www.instagram.com/stromee.energy/"
              rel="noreferrer"
              target="_blank"
            >
              <InstagramIcon
                preserveAspectRatio="xMinYMin meet"
                viewBox="0 0 40 40"
              />
            </a>
            <a
              className={styles.footer__link}
              href="https://www.pinterest.de/stromee_energy/"
              rel="noreferrer"
              target="_blank"
            >
              <PinIcon
                preserveAspectRatio="xMinYMin meet"
                viewBox="0 0 40 40"
              />
            </a>
            <a
              className={styles.footer__link}
              href="https://www.linkedin.com/company/stromee"
              rel="noreferrer"
              target="_blank"
            >
              <LinkedInIcon
                preserveAspectRatio="xMinYMin meet"
                viewBox="0 0 40 40"
              />
            </a>
          </div>
        </div>
        <CustomizedDialogs />
        <div className={`${styles.footer__contacts_stores} d-sm-none`}>
          <StoreButton
            url="https://apps.apple.com/de/app/id1521872083"
            target="_blank"
            text="App Store"
            className={styles.footer__storeButton}
          />
          <StoreButton
            url="https://play.google.com/store/apps/details?id=com.stromee.android"
            target="_blank"
            text="Google Play"
            className={styles.footer__storeButton}
          />
          <ButtonChatSticky
            onClick={() => {
              window.fcWidget.open();
            }}
            variant="grayBorder"
          >
            <ChatIconSticky
              id="chat-icon-sticky-small"
              style={{
                width: "40px",
                height: "40px",
                position: "fixed",
                right: "25px",
                bottom: "25px",
                visibility: "hidden",
                filter: "drop-shadow( 2px 2px 1px rgba(0, 0, 0, .3))",
              }}
              preserveAspectRatio="xMinYMin meet"
              viewBox="0 0 24 24"
            />
          </ButtonChatSticky>
        </div>
        <div className={`${styles.footer__contacts_stores} d-sm-none`}>
          <FaqChat />
        </div>
      </div>
    </div>
  );
};

export default BottomSection;
