import styled from "styled-components";

import { ButtonLink, Button } from "../Button";

export const ButtonFaq = styled(ButtonLink)`
  padding: 19px 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  position: relative;
  min-width: 136px;
  @media (max-width: 992px) {
    margin-right: 0;
    width: 220px;
  }
  @media (max-width: 576px) {
    max-width: 100%;
    width: 343px;
    margin-bottom: 16px;
  }
  @media (min-width: 992px) {
    padding: 19px 33px 19px 50px;
  }
`;

export const ButtonChat = styled(Button)`
  padding: 19px 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 576px) {
    max-width: 100%;
    width: 343px;
    margin-bottom: 32px;
  }
  @media (min-width: 992px) {
    padding: 19px 26px 19px 50px;
  }
`;

export const ButtonName = styled.span`
  margin-left: 8px;
`;

export const ButtonChatSticky = styled(Button)`
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 1001;
  visibility: hidden;
  width: 50px;
  height: 50px;
  & :hover,
  & :active {
    visibility: hidden;
  }
`;
