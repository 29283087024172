import React, { ReactElement } from "react";
import { StoreButtonProps } from "./types";
import styles from "./styles.mod.scss";

function StoreButton({
  url,
  text,
  target,
  className,
}: StoreButtonProps): ReactElement {
  return (
    <div className={`${styles.store_button__container} ${className}`}>
      <a href={url} target={target ? target : "_self"}>
        {text}
      </a>
    </div>
  );
}

export default StoreButton;
