import React, { ReactElement } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";

import { ReactComponent as MailIcon } from "../../assets/mail-blue-40.svg";
import styles from "./styles.mod.scss";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1240,
    },
  },
});

const useStyles = makeStyles({
  textFieldStyle: {
    marginRight: "16px",
    marginLeft: "16px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginBottom: "24px",
      width: "100%",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "#ffff !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c1c7c9 !important",
      borderWidth: "1px !important",
    },
    "& .MuiOutlinedInput-root": {
      width: "285px",
      color: "#263242",
      fontSize: "16px",
      borderRadius: "12px",
      borderColor: "#c1c7c9",
      borderWidth: "1px",
      [theme.breakpoints.down("lg")]: {
        width: "278px",
      },
      [theme.breakpoints.down("md")]: {
        width: "278px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
});

const NewsLetterForm = (): ReactElement => {
  const classes = useStyles();
  return (
    <div className={styles.footer__form}>
      <div id="mc_embed_signup">
        <div className={"d-flex align-items-center"}>
          <div className={"d-lg-none"}>
            <MailIcon
              preserveAspectRatio="xMinYMin meet"
              viewBox="0 0 40 40"
              style={{ width: "33px", height: "100%", marginRight: "20px" }}
            />
          </div>
          <p className={styles.footer__form_title}>
            Mit unserem Newsletter bleibst du immer auf dem Laufenden.
          </p>
        </div>
        <form
          action="https://67de21e7.sibforms.com/serve/MUIEAGyrvcSNZ1lXWn3ocJMal4gk1_vag4GlgKMafxNABxlzsqMiBQj4BbsBfBmw0X76cbR5Sf7PtbeGXLT4nYOymbNXD-9emphKWB_2m5uoQFzmd1o87GFn9nERPk016HtbUxFl1ZbBxLE5mSR8lC37oLbJYjEnpijpfzl4NmuoItrlf6NHR4nidma6yvt0X2Zfb4NbsrDq2wWD"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div
            id="mc_embed_signup_scroll"
            className={styles.footer__form_fieldsWrapper}
          >
            <div className="d-none d-lg-block">
              <MailIcon
                preserveAspectRatio="xMinYMin meet"
                viewBox="0 0 40 40"
              />
            </div>
            <TextField
              className={`${classes.textFieldStyle} email`}
              InputLabelProps={{
                style: {
                  color: "#263242",
                },
              }}
              id="mce-EMAIL"
              name="EMAIL"
              label="Deine E-Mail"
              variant="outlined"
            />
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_526eb00979dcfb355d9dcfb2f_843e3a9f38"
                defaultValue=""
              />
              <input
                type="hidden"
                id="gdpr_36421"
                name="gdpr[36421]"
                value="Y"
                className="av-checkbox "
              />
            </div>
            <div className={`${styles.footer__form_buttonWrapper} clear`}>
              <input
                type="submit"
                value="Abonnieren"
                name="subscribe"
                id="mc-embedded-subscribe"
                className={styles.footer__form_button}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsLetterForm;
