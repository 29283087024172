import React, { useEffect } from "react";

export const useScroll = (isScroll: boolean, ref: any) => {
  useEffect(() => {
    const scrollToRef = () =>
      window.scrollTo(0, ref?.current?.getBoundingClientRect().top);
    if (isScroll) {
      scrollToRef();
    }
  }, []);
};
