import React, { ReactElement } from "react";
import { ButtonChat, ButtonFaq, ButtonName, ButtonChatSticky } from "./styled";
import { ReactComponent as FaqIcon } from "../../assets/faq-icon.svg";
import { ReactComponent as ChatIcon } from "../../assets/chat-faq.svg";
import { ReactComponent as ChatIconSticky } from "../../assets/chat-sticky.svg";

export const FaqChat = (): ReactElement => {
  return (
    <React.Fragment>
      <ButtonFaq to="/faq" variant="white">
        <FaqIcon
          style={{
            width: "24px",
            height: "24px",
            position: "absolute",
            left: "24px",
          }}
          preserveAspectRatio="xMinYMin meet"
          viewBox="0 0 24 24"
        />
        <ButtonName>FAQs</ButtonName>
      </ButtonFaq>
      <ButtonChat
        onClick={() => {
          window.fcWidget.open();
        }}
        variant="white"
      >
        <ChatIcon
          style={{
            width: "24px",
            height: "24px",
            position: "absolute",
            left: "24px",
          }}
          preserveAspectRatio="xMinYMin meet"
          viewBox="0 0 24 24"
        />
        <ButtonName>Chatte mit uns</ButtonName>
      </ButtonChat>
      <ButtonChatSticky
        onClick={() => {
          window.fcWidget.open();
        }}
        variant="grayBorder"
      >
        <ChatIconSticky
          id="chat-icon-sticky"
          style={{
            width: "40px",
            height: "40px",
            position: "fixed",
            right: "105px",
            bottom: "55px",
            visibility: "hidden",
            filter: "drop-shadow( 2px 2px 1px rgba(0, 0, 0, .3))",
          }}
          preserveAspectRatio="xMinYMin meet"
          viewBox="0 0 24 24"
        />
      </ButtonChatSticky>
    </React.Fragment>
  );
};
